import { FullPageSpinner } from "@app/components/ui/FullPageSpinner/FullPageSpinner";
import { ENV } from "@app/constants/env";
import { mapLangToGoogleMapsRegion } from "@app/helpers/language.helper";
import { LoadScript, LoadScriptProps } from "@react-google-maps/api";
import { ReactNode, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

type MapContextDef = {
  isLoaded: boolean;
  loadError?: Error;
};

type MapProviderProps = {
  children: ReactNode;
};

const MAP_LIBRARIES: LoadScriptProps["libraries"] = ["places", "geometry"];

const MapContext = createContext<MapContextDef | null>(null);

export function MapProvider({ children }: MapProviderProps) {
  const { i18n } = useTranslation();

  return (
    <LoadScript
      key={`map-${i18n.language}`}
      googleMapsApiKey={ENV.GOOGLE_KEY}
      libraries={MAP_LIBRARIES}
      language={"en"}
      region={mapLangToGoogleMapsRegion(i18n.language)}
      loadingElement={<FullPageSpinner />}
    >
      {children}
    </LoadScript>
  );
}

export function useMap() {
  return useContext(MapContext) as MapContextDef;
}
