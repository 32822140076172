import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

type RejectionModalProps = {
  open: boolean;
  onReject: (rejectionMessage: string) => void;
  onCancel: () => void;
};

export const RejectionModal = ({ open, onReject, onCancel }: RejectionModalProps) => {
  const { t } = useTranslation();
  const [rejectionMessage, setRejectionMessage] = useState<string>(t("job-application-rejection"));

  const handleRejectionMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectionMessage(event.target.value);
  };

  return (
    <Modal
      title={t("reject-candidate")}
      open={open}
      onOk={() => onReject(rejectionMessage)}
      onCancel={onCancel}
      cancelText={t("Cancel")}
      okText={t("reject-candidate")}
      okType="danger"
    >
      <label htmlFor="rejection-message">{t("reject-candidate-textarea-title")}</label>
      <TextArea
        rows={8}
        id="rejection-message"
        style={{ marginTop: "10px" }}
        placeholder={t("reject-candidate-textarea-placeholder")}
        value={rejectionMessage}
        onChange={handleRejectionMessageChange}
      />
    </Modal>
  );
};
