import { AddressDef, AddressObjDef } from "@app/types/address.types";

export const getFullAddress = (address?: AddressDef) => {
  if (!address) return "";
  return [
    address.street,
    [address.postalCode, address.city].filter(Boolean).join(" "),
    address.country,
  ]
    .filter(Boolean)
    .join(", ");
};

export const parseAddress = (
  address: google.maps.GeocoderResult["address_components"],
  latLng: google.maps.LatLngLiteral
): AddressObjDef => {
  try {
    const country = address.find((i) => i.types.includes("country"));
    const city = address.find((i) => i.types.includes("locality"));
    const street = address.find((i) => i.types.includes("route"));
    const postalCode = address.find((i) => i.types.includes("postal_code"));
    const region = address.find((i) => i.types.includes("administrative_area_level_1"));
    const streetNumber = address.find((i) => i.types.includes("street_number"));

    const finalAddress: AddressDef = {
      country: country?.long_name || "",
      countryShort: country?.short_name || "",
      city: city?.long_name || "",
      cityShort: city?.short_name || "",
      street: [street?.long_name, streetNumber?.long_name].filter(Boolean).join(" "),
      postalCode: postalCode?.long_name || "",
      region: region?.long_name || "",
      regionShort: region?.short_name || "",
    };

    if (!finalAddress.city) {
      const orderOfSelection = [
        "administrative_area_level_5",
        "administrative_area_level_4",
        "administrative_area_level_3",
        "administrative_area_level_2",
        "colloquial_area",
        "sublocality",
        "sublocality_level_5",
        "sublocality_level_4",
        "sublocality_level_3",
        "sublocality_level_2",
        "sublocality_level_1",
        "neighborhood",
        "administrative_area_level_1",
      ];
      for (let i = 0; i < orderOfSelection.length; i += 1) {
        const current = address.find((a) => a.types.includes(orderOfSelection[i]));
        if (current) finalAddress.city = current.long_name;
        if (finalAddress.city) break;
      }
    }
    if (!finalAddress.street) {
      const orderOfSelection = [
        "street_address",
        "premise",
        "intersection",
        "subpremise",
        "point_of_interest",
        "park",
      ];
      for (let i = 0; i < orderOfSelection.length; i += 1) {
        const current = address.find((a) => a.types.includes(orderOfSelection[i]));
        if (current) finalAddress.street = current.long_name;
        if (finalAddress.street) break;
      }
    }

    return {
      address: finalAddress,
      coordinates: latLng,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};
