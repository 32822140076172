import { UserOutlined } from "@ant-design/icons";
import { ENV } from "@app/constants/env";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  CompaniesColumnsDataDef,
  CompanyAdminDef,
  CompanyDef,
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "@app/features/super-admin/types/super-admin.company.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import {
  Avatar,
  Badge,
  Input,
  List,
  Modal,
  Skeleton,
  Space,
  Spin,
  Table,
  TableColumnsType,
} from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompanyModal } from "./components/CompanyModal";

const { Search } = Input;

export const Companies = () => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;
  const navigate = useNavigate();
  // table data
  const companies = useSelector((state: RootState) => state.superAdmin.companies);
  const companiesCount = useSelector((state: RootState) => state.superAdmin.companiesCount);
  // loading states
  const companiesLoading = useSelector((state: RootState) => state.loading.models.superAdmin);
  const [loginAsCompanyLoading, setLoginAsCompanyLoading] = useState(false);
  // table params
  const [keyword, setKeyword] = useState("");
  // modals
  const [companyAdminsModalOpen, setCompanyAdminsModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  // data
  const [selectedCompanyAdmins, setSelectCompanyAdmins] = useState<CompanyAdminDef[]>([]);
  const [selectedCompany, setSelectCompany] = useState<CompanyDef>();

  useEffect(() => {
    dispatch.superAdmin.getCompanies({
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: 0,
      ...(keyword && { keyword }),
    });
  }, [keyword]);

  // Admins modal
  const handleAdminsModalCancel = () => {
    setCompanyAdminsModalOpen(false);
  };

  const loginAsCompany = async (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
    setLoginAsCompanyLoading(true);
    try {
      await dispatch.superAdmin.loginCompany(payload);
      navigate(RoutePaths.DASHBOARD);
    } catch (err) {
      console.error(err);
    }
  };

  // Settings modal
  const handleSettingsModalCancel = () => {
    setSettingsModalOpen(false);
  };

  const changeCompanySettings = async () => {
    handleSettingsModalCancel();
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      dispatch.superAdmin.getCompanies({
        limit: SUPER_ADMIN_PAGE_SIZE,
        offset: pagination.current - 1,
        keyword,
      });
    }
  };

  const getApprovalStatusBadge = (status: ApprovalStatusEnum) => {
    switch (status) {
      case ApprovalStatusEnum.APPROVED:
        return "success";
      case ApprovalStatusEnum.NOT_STARTED:
        return "default";
      case ApprovalStatusEnum.PENDING:
        return "processing";
      case ApprovalStatusEnum.REJECTED:
        return "error";
    }
  };

  const generatedData = (data: CompanyDef[]) => {
    return data.map((item: CompanyDef) => ({
      key: item.id,
      id: item.id,
      name: (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${item.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          {item.name}
        </a>
      ),
      contactEmail: item.contactEmail,
      contactPhone: item.contactPhone,
      approvalStatus: (
        <Badge
          key="approvalStatus"
          status={getApprovalStatusBadge(item.approvalStatus)}
          text={item.approvalStatus}
        />
      ),
      hasPaidForJobAds: (
        <Badge
          key="hasPaidForJobAds"
          status={item.hasPaidForJobAds ? "success" : "default"}
          text={t(item.hasPaidForJobAds ? "paid" : "not paid")}
        />
      ),
      internalComment: item.internalComment,
      operation: (
        <Space size="middle">
          <a
            onClick={() => {
              setSettingsModalOpen(true);
              setSelectCompany(item);
            }}
          >
            {t("Settings")}
          </a>
          {item.admins.length ? (
            item.admins.length === 1 ? (
              <a
                onClick={() => {
                  loginAsCompany(item.admins[0]);
                }}
              >
                {t("Log in")}
              </a>
            ) : (
              <a
                onClick={() => {
                  setCompanyAdminsModalOpen(true);
                  setSelectCompany(item);
                  setSelectCompanyAdmins(item.admins as CompanyAdminDef[]);
                }}
              >
                {t("Admins")}
              </a>
            )
          ) : (
            <a
              onClick={() => {
                loginAsCompany({ targetCompanyId: item.id, controlCompany: true });
              }}
            >
              {t("Log in")}
            </a>
          )}
        </Space>
      ),
    })) as CompaniesColumnsDataDef[];
  };

  const columns: TableColumnsType<CompaniesColumnsDataDef> = [
    { title: "ID", dataIndex: "id", key: "id", width: 210 },
    { title: t("Company name"), dataIndex: "name", key: "title" },
    { title: t("Email"), dataIndex: "contactEmail", key: "contactEmail", width: 150 },
    { title: t("Mobile phone"), dataIndex: "contactPhone", key: "contactPhone", width: 150 },
    { title: t("Approval status"), dataIndex: "approvalStatus", key: "approvalStatus", width: 180 },
    {
      title: t("Has paid for job ads"),
      dataIndex: "hasPaidForJobAds",
      key: "hasPaidForJobAds",
      width: 150,
    },
    {
      title: t("Comment"),
      dataIndex: "internalComment",
      key: "internalComment",
      ellipsis: true,
      width: 150,
    },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 250 },
  ];

  if (loginAsCompanyLoading) {
    return (
      <Space direction="vertical" size="large" style={{ width: "100%", textAlign: "center" }}>
        <div>Logging in as Company</div>
        <Spin />
      </Space>
    );
  }

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for company id, company name or contact email")}
          enterButton={t("Search")}
          size="large"
          onSearch={setKeyword}
          allowClear
        />
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={generatedData(companies)}
          loading={companiesLoading}
          pagination={{
            total: companiesCount,
            showTotal: (total) => `${t("Total")} ${total} ${t("Companies")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
            position: ["bottomLeft"],
          }}
          onChange={handlePageChange}
          scroll={{ x: 1450 }}
        />
      </Space>
      <CompanyModal
        open={settingsModalOpen}
        selectedCompany={selectedCompany}
        onSave={changeCompanySettings}
        onCancel={handleSettingsModalCancel}
      />
      <Modal
        title={`${selectedCompany?.name} admins`}
        open={companyAdminsModalOpen}
        onCancel={handleAdminsModalCancel}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={selectedCompanyAdmins}
          loading={loginAsCompanyLoading}
          renderItem={(item: CompanyAdminDef) => (
            <List.Item
              actions={[
                <a
                  onClick={() => {
                    loginAsCompany(item);
                  }}
                  key="list-login"
                >
                  {t("Log in")}
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  avatar={
                    item.picture ? (
                      <Avatar src={item.picture} />
                    ) : (
                      <Avatar size="small" icon={<UserOutlined />} />
                    )
                  }
                  title={`${item.firstName} ${item.lastName}`}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
