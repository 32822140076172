import { EInfoAdminType } from "../types/super-admin.types";
import {
  CertificateDef,
  CreateCertificateDef,
  CreateHighlightDef,
  CreateTimelineDef,
  EditCertificateDef,
  EditTimelineDef,
  EditUserDef,
  EditUserStatsDef,
  GetUserRelevantJobsDef,
  GetUsersParamsDef,
  HighlightDef,
  HighlightWithPaginationDef,
  TimelineDef,
  UserJobsWithPaginationDef,
  UserStatsResponseDef,
  UsersResponseDef,
  UsersWithPaginationDef,
} from "../types/super-admin.user.types";
import { deleteCached, getCached, postCached, putCached, superAdminApi } from "./super-admin.api";

// USERS
export const getUsers = (params: GetUsersParamsDef) => {
  return superAdminApi.get<UsersWithPaginationDef>("/crafthunts/info_admins", {
    params: {
      ...params,
      type: EInfoAdminType.USERS,
    },
  });
};
export const updateUser = (payload: Partial<EditUserDef>) => {
  return superAdminApi.put<UsersResponseDef>(`/worker_profiles/info/${payload.id}`, payload);
};

// TIMELINE
export const getUserTimeline = (workerProfileId: string) => {
  return getCached<TimelineDef[]>(
    "/crafthunts/timelines" + workerProfileId,
    "/crafthunts/timelines",
    {
      workerProfileId,
    }
  );
};
export const postUserTimeline = (workerProfileId: string, data: CreateTimelineDef) => {
  return postCached<TimelineDef>(
    "/crafthunts/timelines" + workerProfileId,
    "/crafthunts/timelines",
    {
      workerProfileId,
      ...data,
    }
  );
};
export const editUserTimeline = (id: string, workerProfileId: string, data: EditTimelineDef) => {
  return putCached<TimelineDef>(
    "/crafthunts/timelines" + workerProfileId,
    `/crafthunts/timelines/${id}`,
    {
      workerProfileId,
      ...data,
    }
  );
};
export const deleteUserTimeline = (id: string, workerProfileId: string) => {
  return deleteCached("/crafthunts/timelines" + workerProfileId, `/crafthunts/timelines/${id}`, {
    workerProfileId,
  });
};

// CERTIFICATES
export const getUserCertificates = (workerProfileId: string) => {
  return getCached<CertificateDef[]>(
    "/profile_certificates" + workerProfileId,
    "/profile_certificates",
    {
      workerProfileId,
    }
  );
};
export const postUserCertificate = (workerProfileId: string, data: CreateCertificateDef) => {
  return postCached<CertificateDef>(
    "/profile_certificates" + workerProfileId,
    "/profile_certificates",
    {
      workerProfileId,
      ...data,
    }
  );
};
export const editUserCertificate = (
  id: string,
  workerProfileId: string,
  data: EditCertificateDef
) => {
  return putCached<CertificateDef>(
    "/profile_certificates" + workerProfileId,
    `/profile_certificates/${id}`,
    {
      workerProfileId,
      ...data,
    }
  );
};
export const deleteUserCertificate = (id: string, workerProfileId: string) => {
  return deleteCached("/profile_certificates" + workerProfileId, `/profile_certificates/${id}`, {
    workerProfileId,
  });
};
// HIGHLIGHTS
export const getUserHighlights = (workerProfileId: string) => {
  return getCached<HighlightWithPaginationDef>(
    "/crafthunts/images" + workerProfileId,
    "/crafthunts/images",
    {
      workerProfileId,
    }
  );
};
export const postUserHighlight = (workerProfileId: string, data: CreateHighlightDef) => {
  return postCached<HighlightDef>("/crafthunts/images" + workerProfileId, "/crafthunts/images", {
    profileId: workerProfileId,
    ...data,
  });
};
export const deleteUserHighlight = (id: string, workerProfileId: string) => {
  return deleteCached("/crafthunts/images" + workerProfileId, `/crafthunts/images/${id}`, {
    workerProfileId,
  });
};
// STATS
export const getUserStats = (workerProfileId: string) => {
  return getCached<UserStatsResponseDef>(
    "/crafthunts/stats" + workerProfileId,
    "/crafthunts/stats",
    { workerProfileId }
  );
};
export const editUserStats = (data: EditUserStatsDef) => {
  return putCached<UserStatsResponseDef>(
    "/crafthunts/stats" + data.profileId,
    `/crafthunts/stats/${data.id}`,
    data
  );
};
// RELEVANT JOBS
export const getUserRelevantJobs = (params: GetUserRelevantJobsDef) => {
  return getCached<UserJobsWithPaginationDef>(
    "/crafthunts/job_ads" + Object.values(params || {}).join("-"),
    "/crafthunts/job_ads",
    params
  );
};
