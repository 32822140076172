import { CopyOutlined, EditOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "@app/components/ui/Button/Button";
import { DateFormats } from "@app/constants/date.constants";
import { ENV } from "@app/constants/env";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import i18n from "@app/translations/i18n";
import {
  EJobAdStatus,
  IJobAdColumnsDataType,
  JobAdDef,
  JobAdNavigationState,
} from "@app/types/job-ads.types";
import { JobTitleTagDef, TradeTagDef } from "@app/types/tags.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { Badge, Modal, Space, Table, TableColumnsType, TablePaginationConfig, Tooltip } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styles from "./JobAds.module.scss";

const { warning } = Modal;

const PAGE_SIZE = 15;

export const JobAds = () => {
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const savedJobAds = useSelector((state: RootState) => state.jobAds.jobAds);
  const pagination = useSelector((state: RootState) => state.jobAds.pagination);
  const companyAddress = useSelector((state: RootState) => state.companyInfo.companyInfo.address);
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = store;
  const findTagName = (tags: JobTitleTagDef[] | TradeTagDef[], id: string) => {
    const foundTagIndex = tags.findIndex((tag) => id === tag.id);
    return tags[foundTagIndex].name;
  };
  const webUrl = `${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/`;

  useEffect(() => {
    if (!companyAddress.street && !companyAddress.postalCode) {
      warning({
        title: t("Please add company address"),
        content: t(
          "As a craftsman, it's important to know the location of potential job opportunities. To ensure that people can easily find your company and relevant job ads, kindly add your company address. Click the button below and come back to continue adding job ads."
        ),
        okText: t("Set company address"),
        closable: false,
        maskClosable: false,
        keyboard: false,
        onOk: () => {
          navigate(RoutePaths.COMPANY_INFO);
        },
        width: 600,
      });
    }
    return () => {
      Modal.destroyAll();
    };
  }, [companyAddress.street, companyAddress.postalCode]);

  useEffect(() => {
    dispatch.jobAds.getJobAdsAction({
      limit: PAGE_SIZE,
      offset: currentPage,
    });
  }, [currentPage]);

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number") {
      setCurrentPage(pagination.current - 1);
    }
  };

  const generatedColumnData = (data: JobAdDef[]) => {
    const columnData = data.map((item: JobAdDef) => ({
      key: item.id,
      status: (
        <Tooltip title={t(item.status)}>
          <Badge
            key="status"
            status={item.status === EJobAdStatus.DRAFT ? "warning" : "success"}
            className={styles.badge}
          />
        </Tooltip>
      ),
      slug: item.slug,
      title: item.title,
      roleName: t(findTagName(jobTitles, item.roleId)),
      tradeName: t(findTagName(availableTradesTags, item.tradeId)),
      address: [item.address?.postalCode, item.address?.city].filter(Boolean).join(" "),
      createdAt: moment(item.createdAt).format(DateFormats.FULL_DATE_TIME),
      operation: (
        <Space size="small">
          <Tooltip title={t("Edit")}>
            <Link to={item.id}>
              <Button type="default" shape="circle" icon={<EditOutlined />} size="middle" />
            </Link>
          </Tooltip>
          <Tooltip title={t("Duplicate")}>
            <Link
              key="duplicate"
              to={RoutePaths.JOB_AD_NEW}
              state={{ duplicateJobAdId: item.id } as JobAdNavigationState}
            >
              <Button type="default" shape="circle" icon={<CopyOutlined />} size="middle" />
            </Link>
          </Tooltip>
          <Tooltip title={t("View")}>
            <Button
              type="default"
              shape="circle"
              href={`${webUrl}${item.fullSlug}`}
              target="_blank"
              icon={<LinkOutlined />}
              size="middle"
            />
          </Tooltip>
        </Space>
      ),
    }));
    return columnData as IJobAdColumnsDataType[];
  };

  const columns: TableColumnsType<IJobAdColumnsDataType> = [
    { title: null, dataIndex: "status", key: "status", width: 25 },
    { title: t("Job ad ID"), dataIndex: "slug", key: "slug", ellipsis: true, width: 250 },
    { title: t("Job title"), dataIndex: "title", key: "title", ellipsis: true, width: 250 },
    { title: t("Role"), dataIndex: "roleName", key: "role", ellipsis: true, width: 150 },
    { title: t("Trade"), dataIndex: "tradeName", key: "trade", ellipsis: true, width: 150 },
    { title: t("Address"), dataIndex: "address", key: "address", ellipsis: true, width: 200 },
    {
      title: t("Created at"),
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
    },
    {
      title: t("Actions"),
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 145,
    },
  ];

  return (
    <ContentLayout
      title={t("Job ads")}
      description={t(
        "Create construction specific Job Ads to attract new employees. Your Job Ads are automatically matched with relevant candidates."
      )}
      headerItems={[
        <Link key="new" to={RoutePaths.JOB_AD_NEW}>
          <Button type="primary" id="create-job-button">
            <PlusOutlined />
            {t("Add new job ad")}
          </Button>
        </Link>,
      ]}
    >
      <Table
        style={{ width: "100%" }}
        columns={columns}
        scroll={{ x: 1355 }}
        dataSource={generatedColumnData(savedJobAds)}
        pagination={{
          total: pagination.count,
          showTotal: (total) => `${t("Total")} ${total} ${t("Job ads")}`,
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
        onChange={handlePageChange}
      />
    </ContentLayout>
  );
};
