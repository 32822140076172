import {
  getCandidateStatuses,
  getCandidates,
  updateCandidate,
  updateCandidateStatuses,
} from "@app/api/candidates.api";
import {
  CandidateDef,
  CandidatesRequestDef,
  CandidatesResponseDef,
  CompanyCandidateStatusDef,
  UpdateCandidateDef,
} from "@app/types/candidate.types";
import { createModel } from "@rematch/core";
import { store } from "../store";
import { RootModel } from "./models";

const fetchCandidatesInChunks = async (
  payload: CandidatesRequestDef,
  callback: (candidates: CandidateDef[]) => void
): Promise<void> => {
  const { dispatch } = store;
  const itemByPage = 50;
  let offset = 0;
  let count = itemByPage;

  // Fetch candidates in chunks until all results are fetched
  while (count === itemByPage) {
    const result: CandidatesResponseDef = await getCandidates({
      limit: itemByPage,
      offset,
      jobAdIds: payload.jobAdIds,
      companyCandidateStatusId: payload.companyCandidateStatusId,
      method: payload.method,
    });
    if (result.currentPage === 0 && payload.companyCandidateStatusId) {
      dispatch.candidates.setCandidatesCounter({
        statusId: payload.companyCandidateStatusId,
        counter: result.count,
      });
    }
    count = result.data.length;
    callback(result.data);
    // Increment the offset for the next chunk
    offset += 1;
  }
  return;
};

export const candidates = createModel<RootModel>()({
  state: {
    candidateStatuses: [] as CompanyCandidateStatusDef[],
    candidateStatusesLoading: {} as Record<string, boolean>,
    candidatesCounter: {} as Record<string, number>,
    candidates: [] as CandidateDef[],
  },
  reducers: {
    addCandidateStatuses: (state, payload: CompanyCandidateStatusDef[]) => {
      state.candidateStatuses = payload;
      return state;
    },
    clearCandidates: (state) => {
      state.candidates = [];
      return state;
    },
    addCandidates: (state, payload: CandidateDef[]) => {
      state.candidates.push(...payload);
      return state;
    },
    updateCandidate: (state, payload: { candidate: CandidateDef; index: number }) => {
      state.candidates.splice(payload.index, 1, payload.candidate);
      return state;
    },
    setCandidateStatusLoading: (state, payload: { name: string; loading: boolean }) => {
      state.candidateStatusesLoading[payload.name] = payload.loading;
      return state;
    },
    setCandidatesCounter: (state, payload: { statusId: string; counter: number }) => {
      state.candidatesCounter[payload.statusId] = payload.counter;
      return state;
    },
    increaseCounter: (state, payload: { statusId: string }) => {
      state.candidatesCounter[payload.statusId] += 1;
      return state;
    },
    decreaseCounter: (state, payload: { statusId: string }) => {
      state.candidatesCounter[payload.statusId] -= 1;
      return state;
    },
  },
  effects: (dispatch) => ({
    getAllCandidates: async (payload: CandidatesRequestDef) => {
      await fetchCandidatesInChunks(payload, (candidates) => {
        dispatch.candidates.addCandidates(candidates);
      });
    },
    getCandidates: async (payload: CandidatesRequestDef) => {
      const result = await getCandidates(payload);
      dispatch.candidates.addCandidates(result.data);
    },
    getCandidateStatuses: async () => {
      const result = await getCandidateStatuses();
      dispatch.candidates.addCandidateStatuses(result);
    },
    clearAllCandidatesAction: async () => {
      dispatch.candidates.clearCandidates();
    },
    clearAllStatesAction: async () => {
      dispatch.candidates.clearCandidates();
      dispatch.candidates.addCandidateStatuses([]);
    },
    updateCandidateAction: async (
      payload: { candidateId: string; candidateData: UpdateCandidateDef },
      rootState
    ) => {
      const result = await updateCandidate(payload.candidateId, payload.candidateData);
      const candidateIndex = rootState.candidates.candidates.findIndex(
        (i) => i.id === payload.candidateId
      );
      if (result && candidateIndex > -1) {
        const oldCandidate = rootState.candidates.candidates[candidateIndex];
        if (oldCandidate.companyCandidateStatus.id != result.companyCandidateStatus.id) {
          dispatch.candidates.decreaseCounter({
            statusId: oldCandidate.companyCandidateStatus.id,
          });
          dispatch.candidates.increaseCounter({
            statusId: result.companyCandidateStatus.id,
          });
        }

        dispatch.candidates.updateCandidate({ candidate: result, index: candidateIndex });
      }
      return result;
    },
    updateCandidateStatusesAction: async (payload: CompanyCandidateStatusDef[]) => {
      const result = await updateCandidateStatuses(payload);
      if (result) {
        dispatch.candidates.addCandidateStatuses(result);
      }
      return result;
    },
  }),
});
