import Icon from "@ant-design/icons";
import { Colors } from "@app/constants/colors.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { CandidateDef, ECandidateMethod, ECandidateStatus } from "@app/types/candidate.types";
import { Button, Col, Row, Space, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdCancel, MdMessage, MdOpenInFull, MdStar, MdVerified } from "react-icons/md";
import ReactMoment from "react-moment";
import { Link } from "react-router-dom";
import { CandidateName } from "../../../CandidateName/CandidateName";
import styles from "./CandidateCardContent.module.scss";

type CandidateCardContentProps = {
  candidate: CandidateDef;
  onClick: () => void;
  showJobName?: boolean;
};

export const CandidateCardContent = ({
  candidate,
  onClick,
  showJobName,
}: CandidateCardContentProps) => {
  const { t } = useTranslation();

  const isInRecommendedStage =
    candidate.companyCandidateStatus?.status === ECandidateStatus.RECOMMENDED;
  const isActiveSourcedCandidate = candidate.method === ECandidateMethod.CONTACTED;
  const createdAtTextPrefix = isInRecommendedStage
    ? t("recommended-date-prefix")
    : isActiveSourcedCandidate
    ? t("contacted-date-prefix")
    : t("applied-date-prefix");

  return (
    <div onClick={onClick}>
      <Tooltip title={t("open")}>
        <Button
          className={styles.expand}
          onClick={onClick}
          type="text"
          icon={<Icon component={MdOpenInFull} style={{ color: Colors.primary }} />}
        />
      </Tooltip>

      <CandidateName candidate={candidate} shouldTruncate textStyle={styles.smallText} />

      {!!showJobName && !!candidate.jobAds.length && (
        <div style={{ marginBottom: "16px", marginTop: "16px" }}>
          <Typography.Text
            key="job-ad-name"
            type="secondary"
            className={clsx(styles.smallText, styles.truncate)}
          >
            {t("applied-to")}: {candidate.jobAds.at(0)?.title}
          </Typography.Text>
        </div>
      )}

      <div style={{ marginBottom: "16px", marginTop: "16px" }}>
        {candidate.workerProfile.isVerified && (
          <Space align="center">
            <Icon component={MdVerified} style={{ color: Colors.primary }} />
            <Typography.Text key="verified" className={styles.smallText}>
              {t("verified-by-crafthunt")}
            </Typography.Text>
          </Space>
        )}
        {!!candidate.revealIdentity && (
          <>
            {!!candidate.workerProfile.medias?.length && (
              <Space align="center">
                <Icon component={IoIosCheckmarkCircle} style={{ color: Colors.green }} />
                <Typography.Text key="cv" className={styles.smallText}>
                  {t("documents-available")}
                </Typography.Text>
              </Space>
            )}
            {!candidate.workerProfile.medias?.length && (
              <Space align="center">
                <Icon component={MdCancel} style={{ color: Colors.red }} />
                <Typography.Text key="cv" className={styles.smallText}>
                  {t("documents-missing")}
                </Typography.Text>
              </Space>
            )}
          </>
        )}
        {candidate.isCrafthuntRecommended && !isInRecommendedStage && (
          <Space align="center">
            <Icon component={MdStar} style={{ color: Colors.primary }} />
            <Typography.Text key="recommended" className={styles.smallText}>
              {t("recommended-by-crafthunt")}
            </Typography.Text>
          </Space>
        )}
      </div>

      <Row justify="space-between" align="bottom" wrap={false}>
        <Col>
          <Typography.Text key="createdAt" type="secondary" className={styles.smallText}>
            {createdAtTextPrefix}:{" "}
            <ReactMoment fromNow className={styles.capitalize}>
              {candidate.createdAt}
            </ReactMoment>
          </Typography.Text>
        </Col>
        <Col>
          <Tooltip title={t("chat")}>
            <Link to={RoutePaths.CHAT + `?candidateId=${candidate.id}`}>
              <Button icon={<Icon component={MdMessage} style={{ color: Colors.primary }} />} />
            </Link>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};
