import {
  getEngagement,
  recommendCandidate,
} from "@app/features/super-admin/api/super-admin.engagement.api";
import { EngagementDef } from "@app/features/super-admin/types/super-admin.engagement.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { Button, message, Spin } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type EngagementStatusProps = {
  companyId: string;
  userId?: string;
};

export const EngagementStatus = ({ companyId, userId }: EngagementStatusProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [engagement, setEngagement] = useState<EngagementDef>();

  const fetchData = useCallback(async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      const response = await getEngagement(companyId, userId);
      setEngagement(response.data);
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        message.error({
          content: getErrorMessages(err as ApiFormErrorDef),
          key: "get-engagement",
          duration: 10,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [userId, companyId]);

  const handleOnRecommendCandidate = async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      await recommendCandidate(companyId, userId);
      fetchData();
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        message.error({
          content: getErrorMessages(err as ApiFormErrorDef),
          key: "recommend-candidate",
          duration: 10,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId, userId]);

  if (loading) {
    return <Spin />;
  }

  if (engagement) {
    return <p>{engagement.engagementType}</p>;
  }

  return (
    <Button onClick={handleOnRecommendCandidate} type="primary">
      {t("recommend-button")}
    </Button>
  );
};
