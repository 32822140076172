import {
  CompaniesWithPaginationDef,
  CompanyDef,
  CompanyResourceUpdateDto,
  CreateCompanyDef,
  CreateCompanyResponseDef,
  GetCompaniesParamsDef,
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "../types/super-admin.company.types";
import { EInfoAdminType } from "../types/super-admin.types";
import { superAdminApi } from "./super-admin.api";

export const getCompanies = (params: GetCompaniesParamsDef) => {
  return superAdminApi.get<CompaniesWithPaginationDef>("/crafthunts/info_admins", {
    params: {
      ...params,
      type: EInfoAdminType.COMPANIES,
    },
  });
};
export const createCompany = (payload: CreateCompanyDef) => {
  return superAdminApi.post<CreateCompanyResponseDef>("/crafthunts/company_resources", payload);
};
export const loginCompany = (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
  return superAdminApi.post<{ accessToken: string }>("/crafthunts/info_admins", {
    ...payload,
    strategy: "switch_user",
  });
};
export const updateCompanySettings = (payload: CompanyDef) => {
  return superAdminApi.put<CompanyResourceUpdateDto>(
    `/crafthunts/company_resources/${payload.id}`,
    payload
  );
};
