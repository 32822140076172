import { Select, SelectProps } from "antd";

type SelectAutocompleteProps = {
  sortAlphabetically?: boolean;
} & SelectProps;

export const SelectAutocomplete = ({
  sortAlphabetically = true,
  ...rest
}: SelectAutocompleteProps) => {
  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      dropdownMatchSelectWidth={false}
      filterOption={(input, option) =>
        ((typeof option?.label === "string" && option.label) || "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      size="large"
      filterSort={(a, b) => {
        if (sortAlphabetically && typeof a.label === "string" && typeof b.label === "string") {
          return a.label.localeCompare(b.label);
        }
        return 0;
      }}
      {...rest}
    />
  );
};
