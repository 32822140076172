import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  UserAdvancedFormDef,
  UserFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { store } from "@app/store/store";
import { Col, Divider, Form, Row, Switch, message } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";

type UserAdvancedFormProps = {
  selectedUser?: UserFormDef;
};

export const UserAdvancedForm = ({ selectedUser }: UserAdvancedFormProps) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;

  const onSubmitForm = async (_: Partial<UserAdvancedFormDef>, values: UserAdvancedFormDef) => {
    if (selectedUser) {
      try {
        await dispatch.superAdmin.updateUser({
          ...values,
          id: selectedUser.id,
          lang: convertLangForBackend(i18n.language),
        });
        dispatch.superAdmin.setSelectedUser({
          ...selectedUser,
          ...values,
        });
        message.success({ content: t("Saved!"), key: "user-info" });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error({
            content: getErrorMessages(error as ApiFormErrorDef),
            key: "user-info",
            duration: 10,
          });
        }
      }
    }
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      labelAlign="right"
      initialValues={{
        isLocked: selectedUser?.isLocked,
        isTemporary: selectedUser?.isTemporary,
        getScheduledJobRecommendations: selectedUser?.getScheduledJobRecommendations,
      }}
      onValuesChange={onSubmitForm}
      data-hs-do-not-collect="true"
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label={t("Fake user (isTemporary)")}
            name={"isTemporary"}
            valuePropName="checked"
            help={t(
              "When turned on, this user will not receive emails/messages, but they can still use the platform."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Locked")}
            name={"isLocked"}
            valuePropName="checked"
            help={t(
              "DANGER - this prevents the user from using the system. They are not able to login when turned on."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Scheduled Job Recommendations")}
            name={"getScheduledJobRecommendations"}
            valuePropName="checked"
            help={t(
              "DANGER - turning this off will stop the user from receiving scheduled job recommendations per email/phone/whatsapp."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
