import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { Colors } from "@app/constants/colors.constants";
import { IWorkerEmployeesList } from "@app/features/auth/types/auth.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState, store } from "@app/store/store";
import { Avatar, Col, message, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

type ProfileOptionsModalProps = {
  maskClosable: boolean;
  closable: boolean;
  title: string;
  open: boolean;
  profileOptions: IWorkerEmployeesList[];
};

export const ProfileOptionsModal = ({
  maskClosable,
  closable,
  title,
  open,
  profileOptions,
}: ProfileOptionsModalProps) => {
  const { dispatch } = store;
  const loading = useSelector((state: RootState) => state.loading.effects.auth.loginAsUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loginAsUser = (user: IWorkerEmployeesList) => async () => {
    try {
      await dispatch.auth.loginAsUser(user);
      message.success({ type: "success", content: t("Logged in!") }, 1000);
      navigate(RoutePaths.DASHBOARD);
    } catch (e) {
      message.error({ type: "error", content: t("Invalid credentials") });
    }
  };

  const getInitials = (firstName: string, lastName: string) => {
    return [firstName[0]?.toUpperCase(), lastName[0]?.toUpperCase()].filter(Boolean);
  };

  return (
    <Modal maskClosable={maskClosable} closable={closable} footer={null} title={title} open={open}>
      <Row>
        {loading && <LoadingSpinner />}
        {profileOptions.map((profile, index) => (
          <Col
            onClick={loginAsUser(profile)}
            className="hoverable-employee-list"
            xs="24"
            key={index}
          >
            <Row align="middle">
              <Col>
                {profile.workeremployeePicture ? (
                  <Avatar
                    size="large"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    src={profile.workeremployeePicture}
                  />
                ) : (
                  <Avatar
                    size="large"
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      backgroundColor: Colors.primary,
                    }}
                  >
                    {getInitials(profile.workeremployeeFirstname, profile.workeremployeeLastname)}
                  </Avatar>
                )}
              </Col>
              <Col>
                <Typography.Title style={{ marginBottom: "0px", paddingTop: "10px" }} level={5}>
                  {profile.workeremployeeFirstname} {profile.workeremployeeLastname}
                </Typography.Title>
                <Typography.Paragraph>{profile.companyName}</Typography.Paragraph>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};
