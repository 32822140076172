import { RoutePaths } from "@app/features/routes/types/routes.types";
import { Dispatch, RootState } from "@app/store/store";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import SuperAdminPasswordModal from "../SuperAdminPasswordModal/SuperAdminPasswordModal";
import { Companies } from "./components/Companies/Companies";
import { Users } from "./components/Users/Users";

enum TAB_KEYS {
  COMPANIES = "companies",
  USERS = "users",
}
export const SuperAdminStuff = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const loadingSuperAdminCheck = useSelector(
    (state: RootState) => state.loading.effects.superAdmin.superAdminCheck
  );
  const isSuperAdmin = useSelector((state: RootState) => state.auth.parsedToken?.isSuperAdmin);
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const dispatch = useDispatch<Dispatch>();
  const [activeTab, setActiveTab] = useState<TAB_KEYS>(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tab = params.get("tab") as TAB_KEYS;
    return Object.values(TAB_KEYS).includes(tab) ? tab : TAB_KEYS.COMPANIES;
  });
  // modals
  const [superAdminKeyModalOpen, setSuperAdminKeyModalOpen] = useState(false);

  const redirectToDashboard = () => {
    navigate(RoutePaths.DASHBOARD);
  };

  useMount(() => {
    if (superToken) {
      dispatch.tags.getAllCertificates();
    }
  });

  useEffect(() => {
    if (!loadingSuperAdminCheck && !superToken) {
      if (isSuperAdmin) {
        setSuperAdminKeyModalOpen(true);
      } else {
        redirectToDashboard();
      }
    }
  }, [loadingSuperAdminCheck, isSuperAdmin, superToken]);

  const onChangeTab = (key: string) => {
    setActiveTab(key as TAB_KEYS);

    navigate(
      {
        pathname: location.pathname,
        search: createSearchParams({
          tab: key,
        }).toString(),
      },
      {
        replace: true,
      }
    );
  };

  const tabItems: TabsProps["items"] = [
    {
      key: TAB_KEYS.COMPANIES,
      label: t("Companies"),
      children: <Companies />,
    },
    {
      key: TAB_KEYS.USERS,
      label: t("Users"),
      children: <Users />,
    },
  ];

  return (
    <ContentLayout title={t("Super admin stuff")}>
      {superToken && (
        <Tabs
          activeKey={activeTab}
          onChange={onChangeTab}
          items={tabItems}
          style={{ width: "100%" }}
        />
      )}
      <SuperAdminPasswordModal
        open={superAdminKeyModalOpen}
        setOpen={setSuperAdminKeyModalOpen}
        onCancel={redirectToDashboard}
      />
    </ContentLayout>
  );
};
