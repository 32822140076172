import { CandidateDef } from "@app/types/candidate.types";
import { Typography } from "antd";
import clsx from "clsx";
import { useGetCandidateName } from "../../hooks/useGetCandidateName";
import styles from "./CandidateName.module.scss";

type CandidateNameProps = {
  candidate: CandidateDef;
  shouldTruncate?: boolean;
  textStyle?: string;
};

export const CandidateName = ({ candidate, shouldTruncate, textStyle }: CandidateNameProps) => {
  const { getCandidateName } = useGetCandidateName();

  return (
    <Typography.Text
      key="full name"
      strong
      className={clsx(textStyle, shouldTruncate && styles.truncate)}
    >
      {getCandidateName(candidate)}
    </Typography.Text>
  );
};
