import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import {
  deleteUserHighlight,
  getUserHighlights,
} from "@app/features/super-admin/api/super-admin.user.api";
import { HighlightDef, UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { Card, Image, Popconfirm, Space, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HighlightModal } from "./components/HighlightModal/HighlightModal";

type UserHighlightsProps = {
  selectedUser?: UserFormDef;
};

export const UserHighlights = ({ selectedUser }: UserHighlightsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [highlights, setHighlight] = useState<HighlightDef[]>([]);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    if (selectedUser?.id) {
      setLoading(true);
      try {
        const data = await getUserHighlights(selectedUser.id);
        setHighlight(data.data.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnAdd = () => {
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSave = () => {
    handleOnClose();
    fetchData();
  };

  const handleOnDelete = async (highlight: HighlightDef) => {
    try {
      await deleteUserHighlight(highlight.id, highlight.profileId);
      handleOnSave();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "user-highlights",
          duration: 10,
        });
      }
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <Button onClick={handleOnAdd}>{t("Add")}</Button>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {highlights.map((item) => (
          <Card
            key={item.id}
            title={t("Site Photo")}
            extra={
              <Popconfirm
                title={t("Are you sure you want to delete this?")}
                okText={t("Yes")}
                cancelText={t("No")}
                onConfirm={() => handleOnDelete(item)}
              >
                <Button danger>{t("Delete")}</Button>
              </Popconfirm>
            }
            size="small"
          >
            {item.url && <Image src={item.url} />}
          </Card>
        ))}
      </Space>
      {selectedUser && (
        <HighlightModal
          open={open}
          workerProfileId={selectedUser.id}
          onSave={handleOnSave}
          onCancel={handleOnClose}
        />
      )}
    </div>
  );
};
