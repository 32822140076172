import { UserStatsDef } from "@app/features/super-admin/types/super-admin.user.types";
import { IGeoSearchAddress } from "../features/auth/types/auth.types";
import { PaginationRequestDef, PaginationResponseDef } from "./pagination.types";
import { TradeTagDef } from "./tags.types";

export enum ECandidateStatus {
  RECOMMENDED = "recommended",
  PENDING = "pending",
  ACCEPTED = "accepted",
  CUSTOM = "custom",
  HIRED = "hired",
  REJECTED = "rejected",
}

export enum ECandidateMethod {
  APPLIED = "applied",
  CONTACTED = "contacted",
}

export interface IProfile {
  id: string;
  jobTitleId: string | null;
  geoSearchAddress: IGeoSearchAddress | null;
  profileTrades: TradeTagDef[];
  isAnonymous: boolean;
  firstName: string;
  lastName: string;
  picture: string | null;
  slug: string | null;
  anonymousSlug: string | null;
}

export interface IReviewedByUser {
  id: string;
  firstName: string;
  lastName: string;
  picture: string | null;
}

export interface ProfileDocumentDef {
  id: string;
  url: string;
  filename: string;
  type: string;
  createdAt: string;
}

export interface CompanyCandidateStatusDef {
  id: string;
  name: string;
  status: ECandidateStatus;
}

export interface KanbanSettingsFormDef {
  statuses: CompanyCandidateStatusDef[];
}

export type CandidateDef = {
  id: string;
  internalNote: string;
  status: ECandidateStatus;
  companyCandidateStatus: CompanyCandidateStatusDef;
  method: ECandidateMethod;
  workerProfileId: string;
  reviewedById: string;
  crafthuntCompanyId: string;
  revealIdentity: boolean;
  createdAt: string;
  updatedAt: string;
  reviewedBy: {
    id: string;
    firstName: string;
    lastName: string | null;
    picture: string | null;
  };
  workerProfile: {
    id: string;
    jobTitle: {
      id: string;
      name: string;
      colorCode: string;
      hasWhiteText: true;
    };
    geoSearchAddress: {
      lat: number;
      lng: number;
      city: string;
      radius: number;
      country: string;
    };
    address: {
      id: string;
      postalCode: string | null;
      country: string;
      countryCode: string;
      city: string;
      region: string | null;
      municipality: string | null;
      isEU: boolean;
    };
    profileTrades: {
      id: string;
      name: string;
      colorCode: string;
      hasWhiteText: boolean;
    }[];
    medias: ProfileDocumentDef[];
    isAnonymous: boolean;
    isVerified: boolean;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    picture: string | null;
    slug: string | null;
    anonymousSlug: string;
    stats: UserStatsDef;
    deletedAt: string | null;
  };
  jobAds: {
    id: string;
    title: string | null;
    title_original: string;
    slug: string;
    fullSlug: string;
  }[];
  jobQuestionsCount: number;
  jobQuestionAnswersCount: number;
  isCrafthuntRecommended?: boolean;
};

export type UpdateCandidateDef = {
  internalNote?: string | null;
  companyCandidateStatusId?: string | null;
  rejectionMessage?: string | null;
  handleRejection?: boolean;
  jobAdsId?: string[] | null;
};

export type CandidatesRequestDef = PaginationRequestDef & {
  jobAdIds?: string[];
  status?: ECandidateStatus;
  companyCandidateStatusId?: string;
  method?: ECandidateMethod;
};
export type CandidatesResponseDef = PaginationResponseDef<CandidateDef>;
