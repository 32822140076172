import { LinkOutlined } from "@ant-design/icons";
import { Button } from "@app/components/ui/Button/Button";
import { ENV } from "@app/constants/env";
import { getUserRelevantJobs } from "@app/features/super-admin/api/super-admin.user.api";
import { UserFormDef, UserJobsDef } from "@app/features/super-admin/types/super-admin.user.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";
import { IJobAdColumnsDataType } from "@app/types/job-ads.types";
import { JobTitleTagDef, TradeTagDef } from "@app/types/tags.types";
import { Empty, Modal, Table, TableColumnsType, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { confirm } = Modal;

const PAGE_SIZE = 10;

type UserRelevantJobsProps = {
  selectedUser?: UserFormDef;
};

export const UserRelevantJobs = ({ selectedUser }: UserRelevantJobsProps) => {
  const { t, i18n } = useTranslation();
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState<UserJobsDef[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagination, setPagination] = useState<TablePaginationConfig>();

  const webUrl = `${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/`;

  const findTagName = (tags: JobTitleTagDef[] | TradeTagDef[], id: string) => {
    const foundTagIndex = tags.findIndex((tag) => id === tag.id);
    return tags[foundTagIndex].name;
  };

  const fetchData = async (page: number) => {
    if (selectedUser?.id) {
      setLoading(true);
      try {
        const data = await getUserRelevantJobs({
          workerProfileId: selectedUser.id,
          limit: PAGE_SIZE,
          offset: page,
        });
        setJobs(data.data.data);
        setPagination({
          total: data.data.count,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number") {
      setCurrentPage(pagination.current - 1);
    }
  };

  const showDetails = (item: UserJobsDef) => {
    confirm({
      title: "Details",
      content: JSON.stringify(item.matchScoreMetadata, null, 2),
      width: 800,
    });
  };

  const columns: TableColumnsType<IJobAdColumnsDataType> = [
    { title: t("Job title"), dataIndex: "title", key: "title", ellipsis: true, width: 250 },
    {
      title: t("Company"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 150,
    },
    { title: t("Role"), dataIndex: "roleName", key: "role", ellipsis: true, width: 150 },
    { title: t("Trade"), dataIndex: "tradeName", key: "trade", ellipsis: true, width: 150 },
    {
      title: t("Engagement type"),
      dataIndex: "engagementType",
      key: "engagementType",
      ellipsis: true,
      width: 150,
    },
    {
      title: t("Status"),
      dataIndex: "candidateStatus",
      key: "candidateStatus",
      ellipsis: true,
      width: 100,
    },
    { title: t("Score"), dataIndex: "score", key: "score", ellipsis: true, width: 75 },
    {
      title: t("Actions"),
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 150,
    },
  ];

  const generatedColumnData = (data: UserJobsDef[]) => {
    const columnData = data.map((item: UserJobsDef) => ({
      key: item.id,
      title: item.title,
      companyName: item.crafthuntCompany.name,
      roleName: t(findTagName(jobTitles, item.roleId)),
      tradeName: t(findTagName(availableTradesTags, item.tradeId)),
      engagementType: item.engagementType,
      candidateStatus: item.candidateStatus,
      score: `${Math.ceil(item.score * 100)}%`,
      operation: (
        <>
          <Button
            href={`${webUrl}${item.fullSlug}`}
            target="_blank"
            type="link"
            icon={<LinkOutlined />}
            size="small"
          >
            {t("View")}
          </Button>
          <Button type="link" onClick={() => showDetails(item)} size="small">
            Metadata
          </Button>
        </>
      ),
    }));
    return columnData as IJobAdColumnsDataType[];
  };

  const missingUserInfo = [
    !selectedUser?.jobTitleId ? t("Role") : "",
    !selectedUser?.profileTradesId?.length ? t("Trades") : "",
    !selectedUser?.geoSearchAddress ? t("Looking for Work - Address") : "",
  ]
    .filter(Boolean)
    .join(" & ");

  return (
    <Table
      style={{ width: "100%" }}
      loading={loading}
      columns={columns}
      scroll={{ x: 1270 }}
      dataSource={generatedColumnData(jobs)}
      pagination={{
        pageSize: PAGE_SIZE,
        showSizeChanger: false,
        position: ["bottomLeft"],
        ...pagination,
      }}
      onChange={handlePageChange}
      locale={{
        emptyText: (
          <Empty
            style={{ color: "black" }}
            description={
              <span>
                {missingUserInfo.length
                  ? `${t("Missing")}: ${missingUserInfo}`
                  : t("Looks like there are no relevant jobs for this user")}
              </span>
            }
          />
        ),
      }}
    />
  );
};
