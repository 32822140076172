import { store } from "@app/store/store";
import { CandidateDef, UpdateCandidateDef } from "@app/types/candidate.types";
import { Drawer, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScreeningQuestionsDrawer } from "../ScreeningQuestionsDrawer/ScreeningQuestionsDrawer";
import { Documents } from "./components/Documents/Documents";
import { HeaderInfo } from "./components/HeaderInfo/HeaderInfo";
import { InternalNote } from "./components/InternalNote/InternalNote";
import { JobsSelect } from "./components/JobsSelect/JobsSelect";
import { PageButton } from "./components/PageButton/PageButton";
import { StatusSelect } from "./components/StatusSelect/StatusSelect";

type CandidateDrawerProps = {
  onClose: () => void;
  onCandidateChange: () => void;
  open: boolean;
  candidate: CandidateDef;
};

export const CandidateDrawer = ({
  onClose,
  onCandidateChange,
  open,
  candidate,
}: CandidateDrawerProps) => {
  const { t } = useTranslation();
  const [showScreeningQuestions, setShowScreeningQuestions] = useState(false);
  const { dispatch } = store;

  const handleChange = async (payload: UpdateCandidateDef) => {
    await handleUpdateCandidate(payload);
    onCandidateChange();
  };

  const handleUpdateCandidate = async (payload: UpdateCandidateDef) => {
    await dispatch.candidates.updateCandidateAction({
      candidateId: candidate.id,
      candidateData: payload,
    });
  };

  const isUserDeleted = !!candidate.workerProfile.deletedAt;

  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <HeaderInfo candidate={candidate} />
          <JobsSelect candidate={candidate} updateCandidate={handleChange} />
          <StatusSelect candidate={candidate} updateCandidate={handleChange} />
          {candidate.revealIdentity && !isUserDeleted && (
            <Documents
              documents={candidate.workerProfile.medias}
              candidateUserId={candidate.workerProfileId}
            />
          )}
          {!isUserDeleted && (
            <PageButton
              label={t("chat-screening-questions")}
              onClick={() => setShowScreeningQuestions(true)}
            />
          )}
          <InternalNote candidate={candidate} updateCandidate={handleUpdateCandidate} />
        </Space>
      </Drawer>
      {!isUserDeleted && (
        <ScreeningQuestionsDrawer
          open={showScreeningQuestions}
          candidateUserId={candidate.workerProfileId}
          onClose={() => setShowScreeningQuestions(false)}
        />
      )}
    </>
  );
};
