import { QuestionCircleOutlined } from "@ant-design/icons";
import { getJobAds } from "@app/api/job-ads.api";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { EJobAdStatus, JobAdDef } from "@app/types/job-ads.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { Badge, Button, Col, Divider, Input, Row, Select, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CandidatesKanban } from "./components/CandidatesKanban/CandidatesKanban";
import { HelpModal } from "./components/HelpModal/HelpModal";
import { KanbanSettingsModal } from "./components/kanbanSettingsModal/KanbanSettingsModal";

const { Search } = Input;

const LAST_JOB_AD_ID = "candidate_kanban_last_job_ad_id";

export enum SpecialJobSelectValue {
  ALL = "all",
  CONTACTED = "contacted",
}

export const Candidates = () => {
  const { t } = useTranslation();
  const [allJobAds, setAllJobAds] = useState<JobAdDef[]>([]);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentJobAdId, setCurrentJobAdId] = useState<string>(
    () => localStorage.getItem(LAST_JOB_AD_ID) || SpecialJobSelectValue.ALL
  );

  const handleOpenSettings = () => {
    setShowSettingModal(true);
  };

  const handleCloseSettings = () => {
    setShowSettingModal(false);
  };

  const handleOpenHelpModal = () => {
    setShowHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setShowHelpModal(false);
  };

  const handleOnSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    const getAllJobAds = async () => {
      // TODO: add pagination to get all jobs
      const response = await getJobAds({
        limit: 100,
      });
      if (response) {
        setAllJobAds(response.data);
      }
    };
    getAllJobAds();
  }, []);

  useEffect(() => {
    localStorage.setItem(LAST_JOB_AD_ID, currentJobAdId);
  }, [currentJobAdId]);

  return (
    <>
      <ContentLayout title={t("Candidates")} fullHeight>
        <Row
          justify="space-between"
          align="bottom"
          style={{ width: "100%", padding: "0 40px 40px" }}
          gutter={[8, 8]}
        >
          <Col style={{ width: 500 }}>
            <Space direction="vertical">
              <SelectAutocomplete
                id="candidate-selected-job-ad"
                value={currentJobAdId}
                onChange={setCurrentJobAdId}
                defaultActiveFirstOption
              >
                <Select.Option
                  value={SpecialJobSelectValue.ALL}
                  label={`- ${t("all-candidates-select")}`}
                >
                  <strong>{t("all-candidates-select")}</strong>
                  <Divider style={{ margin: "10px 0 0" }} />
                </Select.Option>
                <Select.Option
                  value={SpecialJobSelectValue.CONTACTED}
                  label={`-1 ${t("all-contacted-candidates-select")}`}
                >
                  <strong>{t("all-contacted-candidates-select")}</strong>
                  <Divider style={{ margin: "10px 0 0" }} />
                </Select.Option>
                {allJobAds.map((jobAd) => (
                  <Select.Option key={jobAd.id} value={jobAd.id} label={jobAd.title}>
                    <Space>
                      <span>{jobAd.title} </span>
                      <Tag>
                        <Badge
                          key="status"
                          status={jobAd.status === EJobAdStatus.DRAFT ? "warning" : "success"}
                          text={t(jobAd.status)}
                        />
                      </Tag>
                    </Space>
                  </Select.Option>
                ))}
              </SelectAutocomplete>
              <Search
                placeholder={t("kanban-search-placeholder")}
                onSearch={handleOnSearch}
                style={{ width: 500 }}
                allowClear
                size="large"
              />
            </Space>
          </Col>
          <Col>
            <Space>
              <Button
                type="default"
                size="large"
                onClick={handleOpenHelpModal}
                icon={<QuestionCircleOutlined />}
              >
                {t("Help")}
              </Button>
              <Button type="primary" size="large" onClick={handleOpenSettings}>
                {t("kanban-settings")}
              </Button>
            </Space>
          </Col>
        </Row>

        <CandidatesKanban currentJobAdId={currentJobAdId} searchValue={searchValue} />
      </ContentLayout>
      <KanbanSettingsModal
        open={showSettingModal}
        onCancel={handleCloseSettings}
        onSaved={handleCloseSettings}
      />
      <HelpModal open={showHelpModal} onClose={handleCloseHelpModal} />
    </>
  );
};
