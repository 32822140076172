import { getCandidateQuestions } from "@app/api/job-questions.api";
import { JobQuestionDef } from "@app/types/job-questions.types";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ScreeningQuestions.module.scss";
import { Answer } from "./components/Answer/Answer";
import { allowWordBreak } from "./text.helper";

type ScreeningQuestionsProps = {
  candidateUserId: string;
};

export const ScreeningQuestions = ({ candidateUserId }: ScreeningQuestionsProps) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<JobQuestionDef[]>([]);

  useEffect(() => {
    const initState = async () => {
      try {
        setLoading(true);
        const res = await getCandidateQuestions(i18n.language, candidateUserId);
        setQuestions(res);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    initState();
  }, [candidateUserId]);

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      {!questions.length && <span className={styles.emptyState}>{t("chat-no-questions")}</span>}
      {questions.map((question) => (
        <div className={styles.item} key={question.id}>
          <h2 className={styles.itemTitle}>{allowWordBreak(question.title)}</h2>
          <Answer
            questionId={question.id}
            answerType={question.answerType}
            candidateUserId={candidateUserId}
          />
        </div>
      ))}
    </>
  );
};
