import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { RootState, store } from "@app/store/store";
import { ECandidateStatus, KanbanSettingsFormDef } from "@app/types/candidate.types";
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Typography } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CandidateStatusIcon } from "../CandidateStatusIcon/CandidateStatusIcon";

type KanbanSettingsModalProps = {
  open: boolean;
  onSaved: () => void;
  onCancel: () => void;
};

export const KanbanSettingsModal = ({ open, onSaved, onCancel }: KanbanSettingsModalProps) => {
  const { t } = useTranslation();
  const { dispatch } = store;
  const [form] = Form.useForm<KanbanSettingsFormDef>();
  const allStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const customCandidateStatuses = allStatuses.filter(
    (candidateStatus) => candidateStatus.status === ECandidateStatus.CUSTOM
  );
  const initialValues: KanbanSettingsFormDef = {
    statuses: customCandidateStatuses,
  };

  const getStatusName = (statusType: string) => {
    const companyCandidateStatus = allStatuses.find(
      (candidateStatus) => candidateStatus.status === statusType
    );
    return companyCandidateStatus?.name ?? "status-name-placeholder";
  };

  const handleSave = async (values: KanbanSettingsFormDef) => {
    await dispatch.candidates.updateCandidateStatusesAction(values.statuses);
    onSaved();
    form.resetFields();
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={t("kanban-settings")}
      open={open}
      onOk={form.submit}
      onCancel={handleOnCancel}
      cancelText={t("Cancel")}
      okText={t("Save")}
      okButtonProps={{ htmlType: "submit" }}
    >
      <Typography.Title level={5} style={{ marginBottom: 20 }}>
        {t("hiring-process-title")}
      </Typography.Title>
      <Space direction="vertical" style={{ width: "100%", marginBottom: 20 }}>
        {Object.values(ECandidateStatus).map((status) => {
          if (status !== ECandidateStatus.CUSTOM) {
            return (
              <Input
                key={status}
                prefix={<CandidateStatusIcon candidateStatusName={getStatusName(status)} />}
                disabled
                value={t(getStatusName(status))}
              />
            );
          }
          return (
            <Fragment key={status}>
              <Divider orientation="left" plain orientationMargin={0}>
                {t("custom-statuses-title")}
              </Divider>
              <Form
                form={form}
                name="kanban-settings"
                initialValues={initialValues}
                onFinish={handleSave}
                autoComplete="off"
                data-hs-do-not-collect="true"
              >
                <Form.List name="statuses">
                  {(fields, { add, remove, move }) => (
                    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key} gutter={8}>
                          <Col flex="auto">
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              noStyle
                              rules={[
                                { required: true, message: t("Required") },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }
                                    const otherStatuses = [
                                      ...getFieldValue("statuses"),
                                    ] as KanbanSettingsFormDef["statuses"];
                                    // remove existing item from options
                                    otherStatuses.splice(name, 1);
                                    // check if other statuses are identical in name
                                    if (otherStatuses.find((other) => other?.name === value)) {
                                      return Promise.reject(new Error(t("No duplicate status")));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input placeholder={t("status-name-placeholder")} />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button onClick={() => move(index, index - 1)} disabled={index === 0}>
                              <ArrowUpOutlined />
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              onClick={() => move(index, index + 1)}
                              disabled={index === fields.length - 1}
                            >
                              <ArrowDownOutlined />
                            </Button>
                          </Col>
                          <Col>
                            <Button onClick={() => remove(name)}>
                              <DeleteOutlined />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item noStyle>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          {t("Add status")}
                        </Button>
                      </Form.Item>
                    </Space>
                  )}
                </Form.List>
              </Form>
              <Divider />
            </Fragment>
          );
        })}
      </Space>
    </Modal>
  );
};
