import {
  ICompanyClaimPayload,
  ICompanySignupPayload,
  ILogInWorkerProfileResponse,
  IPasswordChangePayload,
  ISignupWorkerProfileResponse,
  IVerificationWorkerProfileResponse,
} from "@app/features/auth/types/auth.types";
import { api } from "../../../api/api";

export const login = (email: string, password: string) => {
  return api.post<ILogInWorkerProfileResponse>("/worker_profiles/login", {
    email,
    password,
  });
};

export const signup = (payload: ICompanySignupPayload) => {
  return api.post<ISignupWorkerProfileResponse>("/crafthunts/company_signups", payload);
};

export const claim = (payload: ICompanyClaimPayload) => {
  return api.post<ISignupWorkerProfileResponse>("/worker_profiles/signup", {
    ...payload,
    strategy: "original",
  });
};

export const forgotPassword = (email: string) => {
  return api.post<ISignupWorkerProfileResponse>("/worker_profiles/verification", {
    type: "forget",
    step: 1,
    email,
  });
};

export const chooseEmployeeAndLogin = (
  accessToken: string,
  payload: {
    workerProfileId: string;
    targetCompanyId: string;
    targetEmployeeId: string;
  }
) => {
  return api.post<ILogInWorkerProfileResponse>("/worker_profiles/switch", payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const refreshToken = (accessToken: string) => {
  return api.post<{ accessToken: string }>("/auth_refresh", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getMagicLink = () => {
  return api.post<IVerificationWorkerProfileResponse>("/worker_profiles/verification", {
    type: "magic-link",
  });
};

export const changePassword = (workerProfileId: string, payload: IPasswordChangePayload) => {
  return api.put(`/worker_profiles/info/${workerProfileId}`, payload);
};
