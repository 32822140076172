import { sendChatMessage } from "@app/api/chat-messages.api";
import { Kanban } from "@app/features/kanban/components/Kanban/Kanban";
import { RootState, store } from "@app/store/store";
import {
  CandidateDef,
  CompanyCandidateStatusDef,
  ECandidateMethod,
  ECandidateStatus,
} from "@app/types/candidate.types";
import { UniqueIdentifier } from "@dnd-kit/core";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SpecialJobSelectValue } from "../../Candidates";
import { triggerEventAcceptRecommendedCandidate } from "../../helpers/candidates-analytics.helper";
import { useGetCandidateName } from "../../hooks/useGetCandidateName";
import { CandidateDrawer } from "../CandidateDrawer/CandidateDrawer";
import { FirstMessageModal } from "../FirstMessageModal/FirstMessageModal";
import { HiringModal } from "../HiringModal/HiringModal";
import { RejectionModal } from "../RejectionModal/RejectionModal";
import { CandidateCardContent } from "./components/CandidateCardContent/CandidateCardContent";
import { ContainerHeader } from "./components/ContainerHeader/ContainerHeader";

type CandidatesKanbanProps = {
  currentJobAdId?: string;
  searchValue: string;
};
export const CandidatesKanban = ({ currentJobAdId, searchValue }: CandidatesKanbanProps) => {
  const { getCandidateName } = useGetCandidateName();
  const prevCandidatesLength = useRef<number>();
  const prevCandidatesStringified = useRef<string>();
  const prevShouldUpdateCandidates = useRef<string>();
  const [shouldUpdateCandidates, setShouldUpdateCandidates] = useState(uniqueId);
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const candidates = useSelector((state: RootState) => state.candidates.candidates);
  const { dispatch } = store;
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorSendFirstMessage, setErrorSendFirstMessage] = useState(false);
  const [nextStatusId, setNextStatusId] = useState<string>();
  const [showHiringModal, setShowHiringModal] = useState(false);
  const [items, setItems] = useState<Record<string, string[]>>(
    candidateStatuses
      .filter((item) => item.status !== ECandidateStatus.CUSTOM)
      .reduce((result, item) => {
        result[item.name] = [];
        return result;
      }, {} as Record<string, string[]>)
  );
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDef | undefined>();
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false);
  const [kanbanKey, setKanbanKey] = useState<string>(uniqueId);

  const handleClickCandidate = (candidate: CandidateDef) => {
    setSelectedCandidate(candidate);
    setShowCandidateDrawer(true);
  };

  const sortFunction = (items: UniqueIdentifier[]) => {
    return items.slice().sort((a, b) => {
      const candidate1 = candidates.find((item) => item.id === a);
      const candidate2 = candidates.find((item) => item.id === b);
      if (!candidate1 || !candidate2) {
        return 0;
      }
      return new Date(candidate2.createdAt).getTime() - new Date(candidate1.createdAt).getTime();
    });
  };

  const saveCandidate = async (
    candidateId: string,
    newStatus: string,
    rejectionMessage?: string,
    handleRejection?: boolean
  ) => {
    await dispatch.candidates.updateCandidateAction({
      candidateId,
      candidateData: {
        companyCandidateStatusId: newStatus,
        rejectionMessage,
        handleRejection,
      },
    });
  };

  const handleOnCancelHire = () => {
    setKanbanKey(uniqueId);
    setShowHiringModal(false);
  };

  const handleOnHire = () => {
    const candidateStatus = candidateStatuses.find(
      (item) => item.status === ECandidateStatus.HIRED
    );
    if (candidateStatus && selectedCandidate) {
      saveCandidate(selectedCandidate?.id, candidateStatus.id);
    }
    setShowHiringModal(false);
  };

  const handleOnCancelReject = () => {
    setKanbanKey(uniqueId);
    setShowRejectionModal(false);
  };

  const handleOnReject = (rejectionMessage: string) => {
    const candidateStatus = candidateStatuses.find(
      (item) => item.status === ECandidateStatus.REJECTED
    );
    if (candidateStatus && selectedCandidate) {
      saveCandidate(selectedCandidate?.id, candidateStatus.id, rejectionMessage, true);
    }
    setShowRejectionModal(false);
  };

  const handleOnCancelSendFirstMessage = () => {
    setKanbanKey(uniqueId);
    setShowMessageModal(false);
  };

  const handleSendFirstMessage = async (message: string) => {
    try {
      if (nextStatusId && selectedCandidate) {
        await saveCandidate(selectedCandidate?.id, nextStatusId);
        sendChatMessage(message, selectedCandidate.id);
        triggerEventAcceptRecommendedCandidate(selectedCandidate.id);
      }
      setShowMessageModal(false);
    } catch (error) {
      console.error(error);
      setErrorSendFirstMessage(true);
    }
  };

  const handleOnDropped = async (containerId: UniqueIdentifier, itemId: UniqueIdentifier) => {
    const candidateStatus = candidateStatuses.find((item) => item.name === containerId);
    const candidate = candidates.find((item) => item.id === itemId);

    if (candidate?.companyCandidateStatus.name === containerId || !candidateStatus || !candidate) {
      return;
    }

    if (
      candidateStatus.status === ECandidateStatus.RECOMMENDED &&
      candidate.companyCandidateStatus.status !== ECandidateStatus.RECOMMENDED
    ) {
      setKanbanKey(uniqueId);
      return;
    }

    setSelectedCandidate(candidate);
    if (
      candidateStatus.status !== ECandidateStatus.REJECTED &&
      candidate.companyCandidateStatus.status === ECandidateStatus.RECOMMENDED
    ) {
      setNextStatusId(candidateStatus.id);
      setShowMessageModal(true);
    } else if (
      candidateStatus.status === ECandidateStatus.REJECTED &&
      candidate.companyCandidateStatus.status !== ECandidateStatus.RECOMMENDED
    ) {
      setShowRejectionModal(true);
    } else if (candidateStatus.status === ECandidateStatus.HIRED) {
      setShowHiringModal(true);
    } else {
      saveCandidate(candidate.id, candidateStatus.id);
    }
  };

  const isAllJobSelected =
    currentJobAdId === SpecialJobSelectValue.ALL ||
    currentJobAdId === SpecialJobSelectValue.CONTACTED;

  const loadCandidatesForStatus = async (candidateStatus: CompanyCandidateStatusDef) => {
    dispatch.candidates.setCandidateStatusLoading({
      name: candidateStatus.name,
      loading: true,
    });
    const jobAdId = !currentJobAdId || isAllJobSelected ? undefined : [currentJobAdId];
    const method =
      currentJobAdId === SpecialJobSelectValue.CONTACTED ? ECandidateMethod.CONTACTED : undefined;
    await dispatch.candidates.getAllCandidates({
      jobAdIds: jobAdId,
      companyCandidateStatusId: candidateStatus.id,
      method,
    });
    dispatch.candidates.setCandidateStatusLoading({
      name: candidateStatus.name,
      loading: false,
    });
  };

  const loadItems = useCallback(
    (skipKanbanKey?: boolean) => {
      const newItems = candidateStatuses.reduce((result, item) => {
        result[item.name] = candidates
          .filter((candidate) => {
            const userFullName = getCandidateName(candidate);
            const search = searchValue.toLowerCase();

            const candidateIsMatchingSearch =
              userFullName.toLowerCase().includes(search) ||
              candidate.workerProfile.email?.toLowerCase().includes(search) ||
              candidate.workerProfile.phone?.toLowerCase().includes(search);

            return candidate.companyCandidateStatus.name === item.name && candidateIsMatchingSearch;
          })
          .map((candidate) => candidate.id);
        return result;
      }, {} as Record<string, string[]>);
      setItems(newItems);
      if (!skipKanbanKey) {
        setKanbanKey(uniqueId);
      }
    },
    [candidateStatuses, candidates, searchValue]
  );

  useEffect(() => {
    const loadCandidates = async () => {
      await dispatch.candidates.clearAllCandidatesAction();
      for (const candidateStatus of candidateStatuses) {
        loadCandidatesForStatus(candidateStatus);
      }
    };
    loadCandidates();
  }, [currentJobAdId, JSON.stringify(candidateStatuses), searchValue]);

  useEffect(() => {
    loadItems(true);
  }, [JSON.stringify(candidates), loadItems]);

  useEffect(() => {
    if (
      prevShouldUpdateCandidates.current !== shouldUpdateCandidates &&
      prevCandidatesStringified.current !== JSON.stringify(candidates)
    ) {
      prevShouldUpdateCandidates.current = shouldUpdateCandidates;
      prevCandidatesStringified.current = JSON.stringify(candidates);
      loadItems();
    }
  }, [shouldUpdateCandidates, JSON.stringify(candidates), loadItems]);

  useEffect(() => {
    if (prevCandidatesLength.current !== candidates.length) {
      prevCandidatesLength.current = candidates.length;
      loadItems();
    }
  }, [candidates.length, loadItems]);

  return (
    <>
      <div
        style={{
          height: "100%",
        }}
      >
        <Kanban
          key={kanbanKey}
          onDropped={handleOnDropped}
          items={items}
          sortFunction={sortFunction}
          renderContainer={(containerId) => <ContainerHeader containerId={containerId} />}
          renderItem={(value) => {
            const candidate = candidates.find((item) => item.id === value);
            if (!candidate) {
              return <></>;
            }
            return (
              <CandidateCardContent
                candidate={candidate}
                onClick={() => handleClickCandidate(candidate)}
                showJobName={isAllJobSelected}
              />
            );
          }}
        />
      </div>
      {!!selectedCandidate && (
        <CandidateDrawer
          key={selectedCandidate.id + selectedCandidate.companyCandidateStatus.id}
          open={showCandidateDrawer}
          onClose={() => setShowCandidateDrawer(false)}
          candidate={selectedCandidate}
          onCandidateChange={() => setShouldUpdateCandidates(uniqueId)}
        />
      )}
      <FirstMessageModal
        open={showMessageModal}
        onCancel={handleOnCancelSendFirstMessage}
        onSend={handleSendFirstMessage}
        hasError={errorSendFirstMessage}
      />
      <RejectionModal
        open={showRejectionModal}
        onCancel={handleOnCancelReject}
        onReject={handleOnReject}
      />
      <HiringModal open={showHiringModal} onCancel={handleOnCancelHire} onHire={handleOnHire} />
    </>
  );
};
