import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { PhoneNumberInput } from "@app/components/ui/PhoneNumberInput/PhoneNumberInput";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { CompanyDef } from "@app/features/super-admin/types/super-admin.company.types";
import { RootState, store } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { Form, Input, Modal, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";

type CompanyModalProps = {
  open: boolean;
  selectedCompany?: CompanyDef;
  onSave: () => void;
  onCancel: () => void;
};

export const CompanyModal = ({ open, selectedCompany, onSave, onCancel }: CompanyModalProps) => {
  const { t } = useTranslation();
  const { dispatch } = store;
  // forms
  const [form] = useForm<CompanyDef>();
  const companySettingsLoading = useSelector(
    (state: RootState) => state.loading.effects.superAdmin.changeCompanySettings
  );

  useEffect(() => {
    if (selectedCompany) {
      form.setFieldsValue(selectedCompany);
    }
  }, [form, selectedCompany]);

  const handleOnCancel = () => {
    onCancel();
  };

  const changeCompanySettings = async () => {
    const data = form.getFieldsValue();
    if (selectedCompany) {
      await dispatch.superAdmin.changeCompanySettings({
        ...selectedCompany,
        ...data,
      });
      onSave();
    }
  };

  return (
    <Modal
      title={selectedCompany?.name}
      open={open}
      onCancel={handleOnCancel}
      onOk={changeCompanySettings}
      okButtonProps={{ size: "large" }}
    >
      {companySettingsLoading && <LoadingSpinner />}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        labelAlign="right"
        form={form}
        initialValues={selectedCompany}
        data-hs-do-not-collect="true"
      >
        <Form.Item
          label={t("Phone number")}
          name={"contactPhone"}
          rules={[
            {
              type: "string",
              required: true,
              whitespace: true,
              message: t("Required"),
            },
            {
              validator: (_, value) => {
                if (!isPossiblePhoneNumber(value)) {
                  return Promise.reject(t("Not valid phone number"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <PhoneNumberInput placeholder={t("Enter phone number")} />
        </Form.Item>
        <Form.Item
          label={t("Approval status")}
          name={"approvalStatus"}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("Required"),
            },
          ]}
        >
          <SelectAutocomplete
            placeholder={t("Select")}
            options={Object.values(ApprovalStatusEnum).map((item) => ({
              value: item,
              label: item,
            }))}
            sortAlphabetically={false}
          />
        </Form.Item>
        <Form.Item
          label={t("Has paid for job ads")}
          name={"hasPaidForJobAds"}
          valuePropName="checked"
        >
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <Form.Item label={t("Has BauGPT")} name={"hasBauGPT"} valuePropName="checked">
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <Form.Item
          label={t("has-integrations-toggle")}
          name={"hasIntegrations"}
          valuePropName="checked"
        >
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <Form.Item label={t("Is Test Company")} name={"isTestCompany"} valuePropName="checked">
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <Form.Item
          label={t("Comment")}
          name={"internalComment"}
          help={t("Internal comment for us, this is not shared with the company")}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
